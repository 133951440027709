/* eslint-disable max-lines */
import React, {forwardRef, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import Language from 'language'
import {useSelector} from "react-redux"
import Widget, {WidgetRef} from "components/widgets/Widget"
import {
  ChartSelection,
  EmptyObject,
  queryWorkflow,
  WorkflowResultTypes,
} from "classes/workflows/query-workflows/QueryWorkflow"
import {useMemoDeepCached, useRefMounted} from "@biron-data/react-hooks"
import HideableForm from "components/forms/Form.Hideable"
import {ChartFormProps, FormGenericProps, FormKeys, FormType} from "components/forms/Form.types"
import {getCurrentWorkspaceExpanded} from "redux/workspace.selector"
import {chartBql, downloadQueryByToken} from "services/QueryService"
import {batch} from "commons/batch"
import {notification} from 'antd'
import {useLocation} from "react-router-dom"
import useDispatch from "hooks/useDispatch"
import {MetaModel, buildMetricLabel, converterFilterToConfModel, extractSlicerDate, getMetricDef, getAvailableDimensions, ChartFormat, DataSelection, GenericChartTypes, MetricWithMetricDef} from "@biron-data/react-bqconf"
import {downloadDataUrl} from "commons/downloadDataUrl"
import {ChartDividerDtoDetail, ChartDtoDetail, ChartDtoDetailTypes, ChartGenericDtoDetail, ChartTargetDtoDetail} from "types/charts"
import {NormalizedDashboardTypes} from "schemas/dashboard"
import {isEmpty} from "@biron-data/react-components"
import {omit} from 'lodash'
import FormChart from "components/forms/chart/FormChart"
import FormGeneric from "components/forms/Form.Generic"
import {BaseEditionInformation, DividerConf} from "components/workspace/WorkspaceBridge.SiderContainer"
import {ExpandedWorkspace} from "redux/models/workspace"
import {isDisabled, percentageBarChartDisablingReason} from "components/forms/chart/hooks"
import {UseGetChartLayoutForAddType} from "hooks/useGetLayoutForAddChart"
import {useHandleChartCopyToClipboard} from "hooks/useHandleChartCopyToClipboard"
import {UseHandleChartAddType} from "hooks/useHandleAddChart"
import {captureEvent} from "services/GoogleAnalyticsService"
import {getWhenGeneric, isPeriodOverridden} from "commons/selection"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {getChartQueryParams} from "commons/parsers/queries"
import {rowHeight} from "components/dashboard/grid/dashboardGridLayoutUtils"
import {useLanguageResolver} from "@biron-data/react-contexts"

export type OnSnapshot = () => any
export type OnSelectionChange = (newSelection: ChartSelection) => void

interface Props {
  environmentId?: number,
  metaModel: MetaModel,
  dashboard: Pick<NormalizedDashboardTypes, "id" | "title">
  dashboardSelection: DataSelection
  chart: ChartDtoDetailTypes,
  isLinkedTo?: boolean,
  editable?: boolean,
  resizing?: boolean,
  getChartLayoutForAdd: ReturnType<UseGetChartLayoutForAddType>
  scrollToChart: (chartId: number) => void
  scrollToPosition: (position: number) => void
  handleChartAdd: ReturnType<UseHandleChartAddType>
  inLoadingBounds: boolean
}

export type ChartDetailWithoutLayout = Omit<ChartDtoDetail, "x" | "y" | "w" | "h" | "title"> & Partial<Pick<ChartDtoDetail, "title">>
export type ChartGenericDetailWithoutLayout = ChartDetailWithoutLayout & ChartGenericDtoDetail
export type ChartTargetDetailWithoutLayout = ChartDetailWithoutLayout & ChartTargetDtoDetail
export type ChartWithoutDetailLayoutTypes = ChartGenericDetailWithoutLayout | ChartTargetDetailWithoutLayout

export type ChartGenericWithoutLayout =
  Omit<Omit<ChartGenericDtoDetail, "metrics"> & {
    metrics: (Omit<MetricWithMetricDef, "metricDef"> & Partial<Pick<MetricWithMetricDef, "metricDef">>)[]
  }, "x" | "y" | "w" | "h"| "title">
  & {title?: string}
export type ChartTargetWithoutLayout =
  Omit<Omit<ChartTargetDtoDetail, "metricCode" | "metricAlias"> & {
    metrics: (Omit<MetricWithMetricDef, "metricDef"> & Partial<Pick<MetricWithMetricDef, "metricDef">>)[]
  }, "x" | "y" | "w" | "h" | "title" | "viewCode">
  & {title?: string, viewCode?: string}
export type ChartDividerWithoutLayout = Omit<ChartDividerDtoDetail, "x" | "y" | "w" | "h" | "title"> & {title?: string}
export type ChartWithMetricDefLayoutTypes = ChartGenericWithoutLayout
  | ChartTargetWithoutLayout
  | ChartDividerWithoutLayout

const WidgetContainer = forwardRef<WidgetRef, Props>(function WidgetContainer(
  {
    environmentId,
    metaModel,
    dashboard,
    dashboardSelection,
    chart: chartWithPosition,
    isLinkedTo,
    editable,
    resizing,
    getChartLayoutForAdd,
    scrollToChart,
    scrollToPosition,
    handleChartAdd,
    inLoadingBounds,
  },
  refFromParent) {
  const languageResolver = useLanguageResolver()
  const chart: ChartWithMetricDefLayoutTypes = useMemoDeepCached(
    () => {
      switch (chartWithPosition.type) {
        case WidgetTypes.GENERIC: {
          return omit({
            ...chartWithPosition,
            metrics: chartWithPosition.metrics
              .map(chartMetric => {
                const metricDef = getMetricDef(metaModel, chartMetric)
                return {
                  ...chartMetric,
                  metricAlias: metricDef ? buildMetricLabel({
                    ...chartMetric,
                    additionalFilters: converterFilterToConfModel(chartMetric.additionalFilters, getAvailableDimensions(metaModel, chartWithPosition.metrics.map(metric => metric.viewCode))),
                    metricDef,
                  }, languageResolver) : '',
                  metricDef,
                }
              }),
          }, ['x', 'y', 'w', 'h']) as ChartGenericWithoutLayout
        }
        case WidgetTypes.TARGET: {
          const metricDef = getMetricDef(metaModel, chartWithPosition)
          return omit({
            ...chartWithPosition,
            metrics:
              [
                {
                  viewCode: chartWithPosition.viewCode,
                  viewAlias: metaModel.views[chartWithPosition.viewCode]?.alias,
                  metricCode: chartWithPosition.metricCode,
                  metricAlias: metricDef ? buildMetricLabel({
                    additionalFilters: [],
                    titlePartOverrides: {},
                    metricDef,
                  }, languageResolver) : '',
                  metricDef,
                  extraConf: {},
                  additionalFilters: [],
                  titlePartOverrides: {},
                },
              ],
          }, ['x', 'y', 'w', 'h']) as ChartTargetWithoutLayout
        }
        case WidgetTypes.DIVIDER:
          return omit(chartWithPosition, ['x', 'y', 'w', 'h']) as ChartDividerWithoutLayout
        default: {
          const exhaustiveCheck: never = chartWithPosition
          return exhaustiveCheck
        }
      }
    },
    [chartWithPosition, languageResolver, metaModel],
  )
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<WorkflowResultTypes | EmptyObject>()
  const refSelection = useRef<ChartSelection>()
  const refMounted = useRefMounted()
  const workspace = useSelector(getCurrentWorkspaceExpanded) as ExpandedWorkspace
  const location = useLocation()
  const [confEdit, setConfEdit] = useState(false)
  const [genericConfEdit, setGenericConfEdit] = useState(false)
  const refShouldQueryData = useRef(true)
  const ref = useRef<WidgetRef>()

  if (isEmpty(refSelection.current)) {
    refSelection.current = initSelection(chart)
  }

  const queryData = useCallback(
    async (prevChartData?: WorkflowResultTypes | EmptyObject) => {
      let loadingTimeout: NodeJS.Timeout
      const result = await queryWorkflow({
          metaModel,
          dashboard,
          dashboardSelection,
          queryId: `${chart.id}`,
          chart,
          // @ts-ignore
          chartSelectionRaw: refSelection.current,
          prevChartData,
        },
        () => {
          loadingTimeout = setTimeout(() => {
            if (refMounted.current) {
              setLoading(true)
            }
          }, 200)
        },
        languageResolver
      )
      // @ts-ignore
      clearTimeout(loadingTimeout)
      if (refMounted.current) {
        batch(() => {
          setLoading(false)
          setData(result)
          refSelection.current = result.chartSelection || {}
        })
      }
    },
    [metaModel, dashboard, dashboardSelection, chart, languageResolver, refMounted],
  )

  const formType: FormType = useMemo(
    () => ({
      type: FormKeys.CHART_CONF,
      chartType: chart.type,
    }),
    [chart.type],
  )
  const formValue: ChartWithMetricDefLayoutTypes = useMemo(
    () => ({
      ...chart,
      title: chart.title,
    }),
    [chart],
  )
  const handleLinkCopy = useCallback(
    () => {
      // We concat chart id and title to create nicer URI, and support title duplicates
      const uri = `${location.pathname}#${encodeURI(`${chart.id}-${chart.title ?? Language.get('new-chart-title')}`)}`
      window.history.pushState("", "", uri)
      navigator.clipboard.writeText(`${window.location.origin}${uri}`)
      notification.open({
        message: Language.get(`chart-link-button-clicked`),
        duration: 2.5,
        type: "info",
        placement: 'bottomRight',
      })
    }, [chart, location])

  const handleConfEdit = useCallback(
    () => {
      if (chart.type === WidgetTypes.DIVIDER) {
        setGenericConfEdit(true)
      } else {
        setConfEdit(true)
      }
    },
    [chart.type],
  )

  const handleConfUpdate = useCallback((newConf: ChartDetailWithoutLayout | BaseEditionInformation) =>
      dispatch.currentDashboard.chartConfUpdate({
          forBatch: true,
          data: {...newConf, id: chart.id},
        })
        .then(continuation => batch(() => {
          setConfEdit(false)
          setGenericConfEdit(false)
          refSelection.current = undefined
          // because continuation() does not always lead to a modification of chart, queryData could be the same after rerender : in such case we should ensure that a new call to queryData would be done in useEffect
          refShouldQueryData.current = true
          return continuation()
        })),
    [dispatch.currentDashboard, chart.id],
  )

  const handleChartDuplicate = useCallback(
    () => {
      captureEvent({
        category: 'charts',
        action: 'duplicate_chart',
        widgetType: chartWithPosition?.type,
      })
      return handleChartAdd({
        ...chartWithPosition,
        title: Language.get(`dashboard-addFromClipboard-newTitle`, chartWithPosition),
        x: chartWithPosition.x,
        y: chartWithPosition.y + 1,
      }).then(() => {
        scrollToPosition((chartWithPosition.y + 1) * (rowHeight + 20))
      })
    },
    [chartWithPosition, handleChartAdd, scrollToPosition],
  )

  const onConfCopy = useHandleChartCopyToClipboard(dispatch.clipboard.copyChart)

  const handleGenericConfSubmit = useCallback(<T extends BaseEditionInformation>(newConf: T) =>
      handleConfUpdate(newConf),
    [handleConfUpdate],
  )
  const handleConfSubmit = useCallback((newConf: ChartDetailWithoutLayout) =>
      handleConfUpdate(newConf),
    [handleConfUpdate],
  )

  const handleConfCancel = useCallback(
    () => {
      setConfEdit(false)
      setGenericConfEdit(false)
    },
    [],
  )
  const handleDownload = useCallback(() => {
      captureEvent({
        category: 'charts',
        action: 'download_csv',
        widgetType: chart?.type,
      })
      downloadQueryByToken(chart.id, getChartQueryParams(
        dashboardSelection,
        {
          ...refSelection.current,
          pagination: undefined,
        },
        chartWithPosition))
        .then(downloadUrl => window.open(downloadUrl))
      return false
    },
    [chart.id, chart?.type, chartWithPosition, dashboardSelection],
  )
  const setRef = useCallback((target: WidgetRef) => {
    ref.current = target
    // @ts-ignore
    refFromParent(target)
  }, [ref, refFromParent])

  const handleSnapshot = useCallback(() => {
    captureEvent({
      category: 'charts',
      action: 'download_png',
      widgetType: chart?.type,
    })
    downloadDataUrl(`biron_${dashboard.title}_${chart.title}.png`, ref.current?.getDataUrl())
  }, [chart?.type, chart.title, dashboard.title])
  const handleChartDelete = useCallback(
    () => {
      return dispatch.currentDashboard.chartDelete(chart.id)
    },
    [dispatch, chart.id],
  )
  const handleSelectionChange = useCallback(
    (newSelection: ChartSelection) => {
      refSelection.current = newSelection
      // There seem to be a react problem here: on the first call, data is undefined (even though it is filled)
      queryData(data as (WorkflowResultTypes | undefined))
    },
    [queryData, data],
  )

  useEffect(
    () => {
      refShouldQueryData.current = true
    },
    [queryData],
  )

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(
    () => {
      if (refShouldQueryData.current) {
        if (inLoadingBounds) {
          refShouldQueryData.current = false
          queryData(undefined)
        } else if (!loading) {
          setLoading(true)
        }
      }
    })
  /* eslint-enable react-hooks/exhaustive-deps */

  const getBqlRequest = useCallback(() => {
    notification.info({
      duration: 2.5,
      key: 'dashboard-nexusQlCopied-confirmation',
      message: Language.get(`dashboard-nexusQlCopied-confirmation-title`),
      description: Language.get(`dashboard-nexusQlCopied-confirmation-description`),
      placement: 'bottomRight',
    })
    chartBql(dashboard.id, getChartQueryParams(dashboardSelection, refSelection.current, chartWithPosition)).then(({query}) => {
      navigator.clipboard.writeText(query)
    })
  }, [chartWithPosition, dashboard.id, dashboardSelection])

  return <React.Fragment>
    {environmentId && inLoadingBounds && <HideableForm<ChartWithMetricDefLayoutTypes, ChartFormProps> {...{
      visible: confEdit,
      formType,
      renderFormComponent: (props) => <FormChart {...props}/>,
      data: formValue,
      name: formValue.title,
      metaModel,
      workspace,
      dashboardSelection,
      dashboardId: dashboard.id,
      environmentId,
      onConfirm: handleConfSubmit,
      onCancel: handleConfCancel,
    }}/>}
    {inLoadingBounds && <HideableForm<DividerConf, FormGenericProps<DividerConf>>
      renderFormComponent={(props) => <FormGeneric {...props}/>} {...{
      visible: genericConfEdit,
      formType,
      data: {
        title: formValue.title,
        type: formValue.type,
        extraConf: formValue.extraConf,
      } as DividerConf,
      metaModel,
      workspace,
      dashboardId: dashboard.id,
      dashboardSelection,
      environmentId,
      onConfirm: handleGenericConfSubmit,
      onCancel: handleConfCancel,
    }}/>}
    <Widget {...{
      ref: setRef,
      chart,
      loading,
      data,
      selection: refSelection.current,
      isLinkedTo,
      resizing,
      editable,
      inLoadingBounds,
      getBqlRequest,
      onDownload: handleDownload,
      onSnapshot: handleSnapshot,
      onConfirm: handleGenericConfSubmit,
      onConfEdit: handleConfEdit,
      onDelete: handleChartDelete,
      onSelectionChange: handleSelectionChange,
      handleChartDuplicate,
      onConfCopy: () => onConfCopy(chartWithPosition),
      onLinkCopy: handleLinkCopy,
      getChartLayoutForAdd,
      scrollToChart,
    }}/>
  </React.Fragment>
})

export default WidgetContainer

export const initSelection = (chart: ChartWithMetricDefLayoutTypes) => {
  if (!chart.extraConf.displayType) {
    return {}
  }

  const selection: ChartSelection = {}
  if (chart.extraConf.displayType === GenericChartTypes.TABLES) {
    selection.pagination = {
      current: 1,
      pageSize: -1,
    }
    selection.sorters = chart.type === WidgetTypes.GENERIC ? chart.orderBys : []
  }
  const isBarChartPercentageAllowed = chart.type === "generic"
    && chart.extraConf.displayType === GenericChartTypes.BARS
    && !isDisabled(percentageBarChartDisablingReason(chart.metrics.map(metric => ({
      isRatio: metric.metricDef?.asRatio,
    })), chart.slicers.map(() => ({}))))
  const isAreaChartPercentageAllowed = chart.type === "generic"
    && chart.extraConf.displayType === GenericChartTypes.AREA
  const isTableChartPercentageAllowed = chart.type === "generic"
    && chart.extraConf.displayType === GenericChartTypes.TABLES
    && (chart as ChartGenericDtoDetail).slicers.length > 0
  if (isBarChartPercentageAllowed || isAreaChartPercentageAllowed || isTableChartPercentageAllowed) {
    if (chart.extraConf.format && [ChartFormat.AREA_PERCENTAGE, ChartFormat.PERCENTAGE_H_STACKED, ChartFormat.PERCENTAGE_V_STACKED].includes(chart.extraConf.format)) {
      selection.asPercentage = true
    } else if (chart.extraConf.displayType === GenericChartTypes.TABLES && chart.extraConf.asPercentage) {
      selection.asPercentage = chart.extraConf.asPercentage
    } else {
      selection.asPercentage = false
    }
  }
  if (chart.type === "generic" && chart.extraConf.displayType === GenericChartTypes.TABLES) {
    selection.withDateSlicer = Boolean(extractSlicerDate(chart.slicers))
  }
  if (chart.type === "generic" && (chart.extraConf.displayType === GenericChartTypes.BARS || chart.extraConf.displayType === GenericChartTypes.PIE)) {
    selection.sortSeries = Boolean(chart.orderBys?.find(orderBy => orderBy.column === chart.slicers.length && !orderBy.asc))
  }
  if (chart.extraConf.displayType === GenericChartTypes.BARS || chart.extraConf.displayType === GenericChartTypes.LINE || chart.extraConf.displayType === GenericChartTypes.PIE || chart.extraConf.displayType === GenericChartTypes.AREA || chart.extraConf.displayType === GenericChartTypes.SCATTER) {
    selection.displayLabels = Boolean(chart.extraConf.displayLabels)
  }
  if (chart.type === "generic" && chart.extraConf.displayType !== GenericChartTypes.TABLES && chart.extraConf.format) {
    selection.format = chart.extraConf.format
  }
  if (isPeriodOverridden(getWhenGeneric(chart))) {
    selection.withChartOverriddenPeriod = true
  }
  return selection
}

