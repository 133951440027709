/* eslint-disable max-lines */

import {ChartGenericDtoDetail, ChartTargetDtoDetail, ConfChartGenericDtoDetail} from "types/charts"
import {TargetExtendedConfModel} from "components/forms/chart/FormChartTargetCmp"
import {ReactNode} from "react"
import {Dayjs, Granularity, PeriodDynamic, PeriodFixed, PeriodTypes} from "@biron-data/period-resolver"
import {Export, ExportDestination, ExportExecutionDto} from "components/forms/Form.types"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {Environment, User, WorkspacesForAce} from "components/admin/user/UserManager.types"
import {AdditionalDetailsTypes, CheckboxAdditionalDetails} from "@biron-data/react-components"
import {
  ChartFormat,
  ConfLimit,
  ConfOrderBy,
  ConfSlicer,
  DataSelection,
  Format,
  GenericChartTypes,
  MetricGrowthPeriod,
  MetricGrowthType,
  MetricTarget,
  SlicerDateDtoDetail,
  SlicerTarget,
  SortType,
  TargetAffect,
  ConfMetric, ConsolidatedDimension, MetaModelView, MetaModel, ConfDimensionFilterTypes, ConfMetricFilterTypes, DimensionFilterDtoDetail,
  LoadDictionaryEntriesWithoutEnvironment
} from "@biron-data/react-bqconf"
import {Boundaries} from "../selector/boundaries/BoundariesSelector";
import {Mark} from "../selector/mark/MarkSelector";

export interface ConfigCache {
  metricFilters: ConfMetricFilterTypes[]
  metrics: MetricCacheElement[]
  slicers: DimensionCacheElement[]
  options: {
    displayLabels?: boolean
    ignoreMetrics0?: boolean
    asPercentage?: boolean
  }
}

export enum CacheElementType {
  SLICER = "SLICER",
  METRIC = "METRIC"
}

interface CacheElement {
  type: CacheElementType.METRIC | CacheElementType.SLICER
  id: string
  code: string | number
  label?: string
  orderByOrder?: number
  orderByAsc?: boolean
  orderByDefault?: boolean
  orderByIndex?: number
  orderByValue?: string
  limit?: number
  limitDefault?: boolean
  hideOthers?: boolean
  isAxis: boolean
  isDefault: boolean,
}

export interface MetricCacheElement extends CacheElement {
  type: CacheElementType.METRIC
  growth?: {
    period: MetricGrowthPeriod,
    type: MetricGrowthType
  }
  growthInvert?: boolean
  viewAlias: string
  viewCode: string
  metricAlias: string
  isRatio?: boolean
  format?: Format
  additionalFilters: DimensionFilterDtoDetail[]
}

export interface DimensionCacheElement extends CacheElement {
  type: CacheElementType.SLICER
  hasRefDate: boolean
  isSimple?: boolean
}

export type CacheElementTypes = DimensionCacheElement | MetricCacheElement

export interface DisablingReason {
  // category?: "METRIC" | "SLICER" | "FILTER"
  [k: string]: boolean
}

export enum LimitType {
  SIMPLE = "Simple",
  DOUBLE = "Double"
}

interface ConfigurationSort {
  default: {
    column: number,
    asc: boolean
  }
  editable: boolean
  type: SortType
  affect: TargetAffect
}

export type ConfigurationOrderByWithDisablingReasons = (ConfigurationSort & {
  disablingReasons: DisablingReason
})

interface ConfigurationLimit {
  type: LimitType,
  mandatory: boolean,
  default?: {
    enabled: boolean,
    value?: number
  },
  displaySlicerOtherOption: boolean,
  affect: TargetAffect
}

export type ConfigurationLimitWithDisablingReasons = (ConfigurationLimit & {
  disablingReasons: DisablingReason
})

export interface SlicerConfiguration {
  value: SlicerDateDtoDetail,
  disablingReasons: DisablingReason
}

export interface Configuration<T> {
  nextCompatibleTypes: GenericChartTypes[],
  type: T,
  metricTarget?: MetricTarget[],
  slicerTargets?: SlicerTarget[],
  defaultSlicer?: SlicerConfiguration,
  enableDateSlicer?: boolean,
  orderBys?: ConfigurationOrderByWithDisablingReasons[],
  limits?: ConfigurationLimitWithDisablingReasons[],
  isMetricsFilterEnabled: boolean
  isColorOverridable: boolean
}

export interface ConfigurationWithDisabledReasons<T> extends Configuration<T> {
  disablingReasons: DisablingReason
  disabledReasons: string[]
}

export interface ChartType extends Configuration<GenericChartTypes | WidgetTypes.TARGET | WidgetTypes.EXPORT> {
  format: (Omit<Configuration<ChartFormat>, "nextCompatibleTypes"> & {
    disablingReasons: DisablingReason
  })[]
  disablingReasons: DisablingReason
}

export interface ChartTypeWithDisabledReason extends ConfigurationWithDisabledReasons<GenericChartTypes | WidgetTypes.TARGET | WidgetTypes.EXPORT> {
  format?: Omit<ConfigurationWithDisabledReasons<ChartFormat>, "nextCompatibleTypes">[]
}

export interface ExtendedConfModel extends ChartGenericDtoDetail {
  uniqueView: string | null
}

export interface GenericExtendedConfModel extends Omit<ConfChartGenericDtoDetail, "extraConf" | "x" | "y" | "h" | "w" | "type" | "orderBys"> {
  uniqueView: string | null
  dateSlicerGranularity: Granularity | null
  format?: ChartFormat | null
  type: WidgetTypes.GENERIC | WidgetTypes.EXPORT,
  displayType: GenericChartTypes
  displayLabels?: boolean,
  limits?: ConfLimit[]
  asPercentage?: boolean
  orderBys: ConfOrderBy[]
  boundaries?: Boundaries
  marks?: Mark[]
}

export interface ExportExtendedConfModel extends Omit<Export, "filters" | "orderBys"> {
  slicers: ConfSlicer[]
  metrics: ConfMetric[]
  filters: ConfDimensionFilterTypes[]
  metricFilters: ConfMetricFilterTypes[]
  period: PeriodTypes
  uniqueView: string | null
  dateSlicerGranularity: Granularity
  displayType: GenericChartTypes.TABLES
  orderBys: ConfOrderBy[]
  limits?: ConfLimit[]
  ignoreMetrics0: boolean
}

export interface ViewOption {
  label: string | ReactNode
  value: string | null
  description?: string
}


export type PeriodExtendedConfModel = (PeriodDynamic | (Omit<PeriodFixed, "start" | "end"> & { start: Dayjs, end: Dayjs })) & {
  title?: string
}
export type EmptyConfModel = NonNullable<unknown>
export type UserConfModel = User | NonNullable<unknown>
export type FormContent = GenericExtendedConfModel | TargetExtendedConfModel | PeriodExtendedConfModel | ExportExecutionDto | EmptyConfModel


export type TemplateChartFormProps = {
  uniqueViewOptions: ViewOption[];
  metricInvertible: boolean;
  loadDictionaryEntries: LoadDictionaryEntriesWithoutEnvironment
  additionalDetails: AdditionalDetailsTypes[]
  viewsWithMetrics: MetaModelView[];
  unavailableViews: MetaModelView[];
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
  metaModel: MetaModel;
  dashboardSelection: DataSelection
  environmentId: number
}

export interface SimplifiedChartTargetFormProps extends TemplateChartFormProps {
}

export type SimplifiedChartGenericFormProps = TemplateChartFormProps & {
  availableDimensions: ConsolidatedDimension[]
  unavailableDimensions: ConsolidatedDimension[]
  dashboardId: number
}

export type SimplifiedPeriodFormProps = {
  isEditing: boolean
}

export type SimplifiedExportExecutionFormProps = {
  exportDestinations: ExportDestination[]
}

export type SimplifiedUserFormProps = {
  userCanSetPowerUser: boolean
  defaultEmailFqdn: string
}

export type SimplifiedDashboardFormProps = {
  availableDimensionsForFilters: ConsolidatedDimension[],
  loadDictionaryEntries: LoadDictionaryEntriesWithoutEnvironment
  environmentId: number
}

export type SimplifiedUserAcesFormProps = {
  userCanManageAdvancedFeature: boolean
  isBironUser: boolean
  environment: Environment
  availableWorkspacesForAce: WorkspacesForAce[]
}

export type ACESConfModel = { [key: string]: string[] }
export type EmptyProps = NonNullable<unknown>
export type FormProps =
  TemplateChartFormProps
  | SimplifiedPeriodFormProps
  | SimplifiedExportExecutionFormProps
  | EmptyProps
  | SimplifiedUserFormProps

export enum GenericAdditionalDetails {
  displayLabels = "displayLabels",
  ignoreMetrics0 = "ignoreMetrics0",
  asPercentage = "asPercentage",
}
export enum TargetAdditionalDetails {
  printPrevisions = "printPrevisions",
  ignoreSeasonality = "ignoreSeasonality"
}