/* eslint-disable max-lines */
import React, {useCallback, useMemo} from "react"
import {
  ChartTypeWithDisabledReason,
  ConfigurationLimitWithDisablingReasons,
  ConfigurationOrderByWithDisablingReasons,
  DimensionCacheElement,
  DisablingReason,
  FormContent,
  GenericAdditionalDetails,
  LimitType,
  MetricCacheElement,
} from "components/forms/chart/types"
import {
  doesFormatDiffer,
  Format,
  GenericChartTypes,
  MetricGrowth,
  MetricGrowthType,
  OrderBy,
  SortType,
  TargetAffect,
} from "@biron-data/react-bqconf"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {
  AdditionalDetailsType,
  AdditionalDetailsTypes,
  CheckboxAdditionalDetails,
  CustomAdditionalDetails
} from "@biron-data/react-components"
import {useMemoDeepCached} from "@biron-data/react-hooks"
import {getAvailableAdditionalDetails} from "components/forms/chart/utils"
import MarkSelector, {Mark} from "../selector/mark/MarkSelector";


export const hasMetricAsRatio = (metrics: { isRatio?: boolean }[]) => Boolean(metrics?.some(metric => metric.isRatio))
export const hasNotEnoughData = (
  metrics: Partial<MetricCacheElement>[],
  slicers: Partial<DimensionCacheElement>[]) => metrics.length === 0 || (metrics.length <= 1 && slicers.length === 0) || (metrics.length === 1 && slicers.length === 1)

export const hasMultipleFormat = (metrics: Pick<MetricCacheElement, "format">[]) => {
  const formats = metrics.map(m => m.format)

  return formats.filter((format, i) => {
      return i !== 0
        && format
        && formats[i - 1]
        && doesFormatDiffer(format, formats[i - 1] ?? {} as Format)
    },
  ).length > 0
}

export const hasMetricWithGrowthAsRatio = (metrics: Pick<MetricCacheElement, "growth">[]) => Boolean(metrics?.some(({growth}) => growth?.type === MetricGrowthType.RATIO))

const hasMoreThanOneSlicer = (slicers: Partial<DimensionCacheElement>[]) => Boolean(slicers.length > 2)

export const percentageBarChartDisablingReason = (metrics: {
  isRatio?: boolean,
  format?: Format
}[], slicers: Partial<DimensionCacheElement>[]): DisablingReason => ({
  hasMultipleFormat: hasMultipleFormat(metrics),
  notEnoughData: hasNotEnoughData(metrics, slicers),
  withMetricAsRatio: hasMetricAsRatio(metrics),
  withMultipleMetricAndOneSlicer: metrics.length > 1 && slicers.length === 1,
})

export const getAreaDisablingReason = (metrics: {
  isRatio?: boolean,
  format?: Format,
  growth?: MetricGrowth
}[], slicers: Partial<DimensionCacheElement>[]) => ({
  withMetricAsRatio: hasMetricAsRatio(metrics),
  withMetricWithGrowthAsRatio: hasMetricWithGrowthAsRatio(metrics),
  withMetricMultiple: hasMetricMultiple(metrics),
  withMoreThanOneSlicer: hasMoreThanOneSlicer(slicers),
})

export const isDisabled = (disablingReasons: DisablingReason) => Object.values(disablingReasons).filter(disablingReason => disablingReason).length > 0

export const useIsFirstSlicerOfTypeDate = (slicers: Pick<DimensionCacheElement, "code" | "isDefault" | "isAxis">[]) => useMemo(() => slicers && slicers.length > 0 && slicers[0].code === "date", [slicers])

export const useIsChartFormatDisplayed = (chartTypes: ChartTypeWithDisabledReason[]) => useMemo(() => chartTypes.length > 1, [chartTypes.length])

export const useDateSlicer = (slicers: Pick<DimensionCacheElement, "code" | "isDefault" | "isAxis">[]) => useMemo(() => slicers.find(slicer => slicer.code === "date"), [slicers])

export const useWithSlicer = (slicers: Pick<DimensionCacheElement, "code" | "isDefault" | "isAxis">[]) => useMemo(() => Boolean(slicers && slicers?.length > 0), [slicers])

export const useWithoutSlicer = (slicers: Pick<DimensionCacheElement, "code" | "isDefault" | "isAxis">[]) => {
  const withSlicer = useWithSlicer(slicers)
  return !withSlicer
}
export const hasMetricMultiple = (metrics: Partial<MetricCacheElement>[]) => Boolean(metrics && metrics?.length > 1)

export const useWithAxisDateSlicer = (slicers: Pick<DimensionCacheElement, "code" | "isDefault" | "isAxis">[]) => {
  const dateSlicer = useDateSlicer(slicers)
  return Boolean(dateSlicer && dateSlicer.isAxis)
}

export const hasSlicerMultiple = (slicers: Partial<DimensionCacheElement>[]) => Boolean(slicers && slicers?.length > 1)

export const useOrderByMetricDesc = (slicers: Pick<DimensionCacheElement, "code" | "isDefault" | "isAxis">[]): OrderBy => useMemo(() => ({
  column: slicers.length,
  asc: false,
}), [slicers.length])

export const useOrderBySlicersAsc = (): OrderBy => useMemo(() => ({
  column: 0,
  asc: true,
}), [])

export const useDefaultAxisOrderBy = (
  withSlicer: boolean,
  withAxisDateSlicer: boolean,
  orderBySlicersAsc: OrderBy,
  orderByMetricDesc: OrderBy,
): ConfigurationOrderByWithDisablingReasons => useMemo(() => ({
  type: SortType.CLASSIC,
  default: withSlicer ? orderBySlicersAsc : orderByMetricDesc,
  affect: TargetAffect.AXIS,
  editable: !withAxisDateSlicer,
  disablingReasons: {},
}), [orderByMetricDesc, orderBySlicersAsc, withAxisDateSlicer, withSlicer])

export const usePieOrderBys = (
  withSlicer: boolean,
  withMetricMultiple: boolean,
  withoutSlicer: boolean,
  orderBySlicersAsc: OrderBy,
  orderByMetricDesc: OrderBy,
): ConfigurationOrderByWithDisablingReasons[] => useMemo(() => ([
  {
    type: SortType.CLASSIC,
    editable: true,
    default: withSlicer ? orderBySlicersAsc : orderByMetricDesc,
    affect: TargetAffect.PART,
    disablingReasons: {
      withSlicer,
    },
  },
  {
    type: SortType.CLASSIC,
    editable: true,
    default: orderByMetricDesc,
    affect: TargetAffect.PART,
    disablingReasons: {
      withMetricMultiple,
      withoutSlicer,
    } as DisablingReason,
  },
]), [orderByMetricDesc, orderBySlicersAsc, withMetricMultiple, withSlicer, withoutSlicer])


export const useTableOrderBys = (
  orderBySlicersAsc: OrderBy,
): ConfigurationOrderByWithDisablingReasons => useMemo(() => ({
  default: orderBySlicersAsc,
  editable: true,
  affect: TargetAffect.TABLE,
  type: SortType.ARRAY,
  disablingReasons: {},
}), [orderBySlicersAsc])

export const useScatterChartLimits = (
  withoutSlicers: boolean,
  withSlicerMultiple: boolean,
) => {
  return useMemo(() => [
    {
      type: LimitType.SIMPLE,
      affect: TargetAffect.NUMBER,
      mandatory: true,
      default: {
        enabled: true,
        value: 20,
      },
      displaySlicerOtherOption: true,
      disablingReasons: {
        withSlicerMultiple,
        withoutSlicers,
      },
    },
    {
      type: LimitType.DOUBLE,
      affect: TargetAffect.NUMBER,
      mandatory: true,
      default: {
        enabled: true,
        value: 20,
      },
      displaySlicerOtherOption: true,
      disablingReasons: {
        withoutSlicers,
      } as DisablingReason,
    },
    {
      type: LimitType.DOUBLE,
      affect: TargetAffect.COLOR,
      mandatory: true,
      default: {
        enabled: true,
        value: 20,
      },
      disablingReasons: {
        withoutSlicers,
      } as DisablingReason,
      displaySlicerOtherOption: true,
    },
  ], [withSlicerMultiple, withoutSlicers])
}

export const useBarChartLimits = (
  isFirstSlicerOfTypeDate: boolean,
  withSlicer: boolean,
  withSlicerMultiple: boolean,
  withOnlyOneSlicer: boolean,
): ConfigurationLimitWithDisablingReasons[] => {
  return useMemo(() => [
    {
      type: LimitType.SIMPLE,
      affect: TargetAffect.AXIS,
      mandatory: true,
      default: {
        enabled: !isFirstSlicerOfTypeDate && withSlicer,
        value: 20,
      },
      displaySlicerOtherOption: !isFirstSlicerOfTypeDate,
      disablingReasons: {
        withSlicerMultiple,
      },
    },
    {
      type: LimitType.DOUBLE,
      affect: TargetAffect.AXIS,
      mandatory: false,
      default: {
        enabled: !isFirstSlicerOfTypeDate,
        value: 20,
      },
      displaySlicerOtherOption: true,
      disablingReasons: {
        withOnlyOneSlicer,
      } as DisablingReason,
    },
    {
      type: LimitType.DOUBLE,
      affect: TargetAffect.COLOR,
      mandatory: true,
      default: {
        enabled: true,
        value: 20,
      },
      disablingReasons: {
        withOnlyOneSlicer,
      } as DisablingReason,
      displaySlicerOtherOption: true,
    },
  ], [isFirstSlicerOfTypeDate, withOnlyOneSlicer, withSlicer, withSlicerMultiple])
}

export const useGetLineChartLimits = (
  isFirstSlicerOfTypeDate: boolean,
  withSlicerMultiple: boolean,
  withOnlyOneSlicer: boolean,
) => {

  return useCallback((affect: TargetAffect): ConfigurationLimitWithDisablingReasons[] => {
    return [
      {
        type: LimitType.SIMPLE,
        affect: TargetAffect.AXIS,
        mandatory: false,
        default: {
          enabled: !isFirstSlicerOfTypeDate,
          value: 20,
        },
        displaySlicerOtherOption: true,
        disablingReasons: {
          withSlicerMultiple,
        },
      },
      {
        type: LimitType.DOUBLE,
        affect: TargetAffect.AXIS,
        mandatory: false,
        default: {
          enabled: !isFirstSlicerOfTypeDate,
          value: 20,
        },
        displaySlicerOtherOption: false,
        disablingReasons: {
          withOnlyOneSlicer,
        },
      },
      {
        type: LimitType.DOUBLE,
        affect,
        mandatory: false,
        default: {
          enabled: true,
          value: 20,
        },
        displaySlicerOtherOption: true,
        disablingReasons: {
          withOnlyOneSlicer,
        },
      }]
  }, [isFirstSlicerOfTypeDate, withOnlyOneSlicer, withSlicerMultiple])
}

export const useHandlePartialFormDataChange = <T extends FormContent>(setData: (newData: (newData: T) => T) => void) => useCallback((newFormData: Partial<T>) => {
  setData(previousFormData => ({
    ...previousFormData,
    ...newFormData,
  }))
}, [setData])

export const useAdditionalDetails = (type: WidgetTypes, displayType?: GenericChartTypes): AdditionalDetailsTypes[] => useMemoDeepCached(() => [
  ...getAvailableAdditionalDetails(type, displayType).map(details => ({
    type: AdditionalDetailsType.checkbox,
    textKey: details,
    hideDescription: details === GenericAdditionalDetails.asPercentage ? true : undefined
  } as CheckboxAdditionalDetails)).filter(Boolean),
  ...((displayType === GenericChartTypes.GAUGE) ? [{
    type: AdditionalDetailsType.custom,
    textKey: 'marks',
    renderComponent: (props: {value: Mark[], onChange: (props: Mark[]) => void}) => <MarkSelector value={props.value} onChange={props.onChange}/>
  } as CustomAdditionalDetails] : []),],
  [type, displayType]
)