import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react"
import * as echarts from "echarts"
import {EChartOption, ECharts, LineSeriesOption} from "echarts"
import {LEGEND_ID, THEME} from "components/charts/Chart.constants"
import {EChartTheme} from "components/charts/Chart.Theme"
import ReactECharts from "echarts-for-react"
import {EChartsReactProps} from "echarts-for-react/lib/types"
import {asArray} from "commons/asArray"
import {formatValue} from "commons/format/formatter"
import {
  standardGridOptions,
  standardLegendOption,
  standardSerieBlur,
  standardSerieEmphasis,
  standardTextStyle,
} from "components/charts/Chart.options"
import * as _ from "lodash"
import {IconContainer} from "@biron-data/react-components"
import {ExclamationIcon} from "@heroicons/react/solid"
import styled from "styled-components"
import {Format} from "@biron-data/react-bqconf"

echarts.registerTheme(THEME, EChartTheme)

interface Props extends EChartsReactProps {
  dimensions: {
    height: number,
    width: number,
  },
  xAxisName?: string,
  yAxisName?: string,
  // sidePart is only working for rectangle chart for now
  sidePart?: ReactNode,
  xAxisFormats?: Format[]
  yAxisFormats?: Format[]
  isValueAxisOnYAxis?: boolean
  isValueAxisOnXAxis?: boolean
  includeLegend?: boolean
  sideLegend?: boolean
  events?: Record<string, (...data: any) => any>
  footer?: ReactNode
  warning?: string
  title?: string
}

type EChartsInstance = {
  getEchartsInstance: () => ECharts
}

export interface BaseChartRef {
  getDataUrl: () => string | undefined
  getEchartsInstance: () => echarts.ECharts | undefined
}

const ChartBase = forwardRef<BaseChartRef, Props>(function BaseChart({
                                                                       xAxisFormats = [],
                                                                       yAxisFormats = [],
                                                                       ...props
                                                                     }, ref) {
  const echartRef = useRef<EChartsInstance>(null)

  const clickCallback = useCallback((data: { seriesType: string, dataIndex?: number, seriesIndex?: number }) => {
    echartRef.current?.getEchartsInstance().dispatchAction({
      type: 'legendScroll',
      scrollDataIndex: data.seriesType === 'pie' ? data.dataIndex : data.seriesIndex,
      legendId: LEGEND_ID,
    })
  }, [echartRef])

  // To prevent user from unselecting we reselect automatically
  // SelectedMode breaks other interactive aspects
  // https://github.com/apache/echarts/issues/11883
  const legendSelectCallback = useCallback((data: { name: string }) => {
    // Re-select what the user unselected
    echartRef.current?.getEchartsInstance().dispatchAction({
      type: 'legendSelect',
      name: data.name,
    })
  }, [])
  const events = {
    click: clickCallback,
    legendselectchanged: legendSelectCallback,
    ...props.events,
  }

  useImperativeHandle(ref, () => ({
    getDataUrl: (): string | undefined => {
      return echartRef.current?.getEchartsInstance().getDataURL({
        excludeComponents: ["toolbox"],
      })
    },
    getEchartsInstance: (): echarts.ECharts | undefined => {
      return echartRef.current?.getEchartsInstance()
    },
  }), [echartRef])

  const legendAndToolbarHeight = (props.includeLegend && !props.sideLegend) ? 30 : 0
  const padding = 10
  const sideLegendWidth = props.sideLegend && props.includeLegend ? 120 : 0
  const {option}: { option: EChartOption<LineSeriesOption> } = props

  const consolidatedOptions = useMemo(() => {
    return ({
      ...option,
      grid: _.merge(
        standardGridOptions(padding, legendAndToolbarHeight),
        {
          width: props.dimensions.width - (padding * 4) - sideLegendWidth - (props.sideLegend ? 10 : 0) - (props.yAxisName ? 20 : 0),
        }, props.option.grid,
      ),
      series: option.series?.map((serie) => _.merge({
        emphasis: standardSerieEmphasis(),
        blur: standardSerieBlur(),
        label: standardTextStyle(),
      }, serie)),
      yAxis: asArray(props.option.yAxis).map((axis: echarts.EChartOption.YAxis, index) => _.merge({
        axisLabel: {
          formatter: yAxisFormats.length > 0 ? (value: string) => {
            return formatValue(value, {...yAxisFormats[index], summarizeValue: true})
          } : undefined,
          ...standardTextStyle(),
        },
        splitLine: {
          lineStyle: {
            color: "#eee",
          },
        },
      }, axis)),
      xAxis: asArray(option.xAxis).map((axis: echarts.EChartOption.XAxis, index) => _.merge({
        axisLabel: {
          formatter: xAxisFormats.length > 0 ? (value: string) => {
            return formatValue(value, {...xAxisFormats[index], summarizeValue: true})
          } : undefined,
          ...standardTextStyle(),
        },
        axisLine: {
          lineStyle: {
            color: "#DCE0E4",
          },
        },
      }, axis)),
      legend: _.merge(
        standardLegendOption(Boolean(props.sideLegend), sideLegendWidth),
        {show: props.includeLegend},
        props.option.legend),
    })
  }, [legendAndToolbarHeight, option, props.dimensions.width, props.includeLegend, props.option.grid, props.option.legend, props.option.yAxis, props.sideLegend, props.yAxisName, sideLegendWidth, xAxisFormats, yAxisFormats])

  const [warningHeight, setWarningHeight] = useState(0)
  const [titleHeight, setTitleHeight] = useState(0)

  const chartHeight = useMemo(() => {
    const xAxisOffset = props.xAxisName ? 15 : 0;
    const titleOffset = props.title ? titleHeight : 0;

    return props.dimensions.height - warningHeight - xAxisOffset - titleOffset;
  }, [props.dimensions.height, props.title, props.xAxisName, titleHeight, warningHeight])

  const sidePartSize = useMemo(() => {
    const halfOfFreeSpace = `calc(100% / 2)`
    if (props.yAxisName && props.sidePart) {
      return `calc(${halfOfFreeSpace} - 20px)`
    } else if (props.yAxisName) {
      return "20px"
    } else if (props.sidePart) {
      return halfOfFreeSpace
    }
    return undefined
  }, [props.sidePart, props.yAxisName])

  const chartWidth = useMemo(() => {
    const halfOfFreeSpace = `calc(100% / 2)`
    if (props.sidePart) {
      return `calc(${halfOfFreeSpace} - 20px)`
    }
    return undefined
  }, [props.sidePart])

  return <>
    {props.title && <Title
        ref={(node) => setTitleHeight(node?.offsetHeight ?? 0)}
        style={{paddingTop: "2%"}}
    >{props.title}</Title>}
    <ChartContainer $sidePartSize={sidePartSize} $chartWidth={chartWidth} data-testid={"chart-container"}>
      {props.yAxisName && <YAxisName>{props.yAxisName}</YAxisName>}
      {props.sidePart && props.sidePart}
      <EChartContainer>
        <ReactECharts
          {...props}
          // @ts-ignore
          ref={echartRef}
          option={consolidatedOptions}
          style={{height: chartHeight}}
          theme={THEME}
          onEvents={events}
          notMerge={props.notMerge ?? true}
        />
      </EChartContainer>
    </ChartContainer>
    <FooterContainer>
      <div>{props.footer}</div>
      {props.xAxisName && <XAxisName>{props.xAxisName}</XAxisName>}
    </FooterContainer>
    {props.warning && props.warning && <WarningMessage ref={(node) => setWarningHeight(node?.offsetHeight ?? 0)}>
      {props.warning}
      <IconContainer><ExclamationIcon/></IconContainer>
    </WarningMessage>}
  </>
})

export default ChartBase

const Title = styled.div`
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 12px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

`

const XAxisName = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 10px;
    line-height: 15px;
`

const YAxisName = styled(XAxisName)`
    writing-mode: tb-rl;
    transform: rotate(-180deg);
`
const EChartContainer = styled.div`
`
const ChartContainer = styled.div<{ $sidePartSize?: string, $chartWidth?: string }>`
  ${({$sidePartSize, $chartWidth}) => {
      if ($sidePartSize) {
          return `display: grid;
        grid-template-columns: ${$sidePartSize} ${$chartWidth ?? `calc(100% - ${$sidePartSize})`};`
      }
      return 'display: block; width: 100%;'
  }}
`
const FooterContainer = styled.div`
  display: grid;
  width: calc(100% - 15px);
  margin-left: 15px;
  grid-template-columns: repeat(3, calc(100% / 3));
`
const WarningMessage = styled.div`
  display: flex;
  flex: 0 0 70%;
  flex-direction: row-reverse;
  text-align: right;
  font-size: 0.7em;
  line-height: 1em;
  color: var(--light-text);
  align-items: center;
  gap: 8px;
`