import React, {FunctionComponent, useCallback} from 'react'
import {Radio, Row} from 'antd'
import ChartTypeItem from "./ChartTypeItem"
import {Props as ChartTypeProps} from "components/forms/confItems/ChartType"
import styled from "styled-components"
import {ChartTypeWithDisabledReason} from "components/forms/chart/types"
import {RadioChangeEvent} from "antd/lib/radio/interface"
import {useConsolidatedChartType} from "components/forms/selector/chartType/ChartTypeSelector.hooks"
import {GenericChartTypes} from "@biron-data/react-bqconf"
import {useFixValue} from "@biron-data/react-hooks"

interface Props extends ChartTypeProps {
  value?: GenericChartTypes,
  onChange?: (data: GenericChartTypes) => void,
  chartTypes: ChartTypeWithDisabledReason[]
}

const ChartTypeSelector: FunctionComponent<Props> = ({
                                                       chartTypes,
                                                       value: originalValue,
                                                       onChange,
                                                     }) => {
  const handleChange = useCallback((e: RadioChangeEvent) => {
      onChange?.(e.target.value)
    },
    [onChange],
  )

  const value = useConsolidatedChartType(chartTypes, originalValue)
  useFixValue(onChange, originalValue, value)

  const getFactor = useCallback((index: number) => 4, [])

  return <FlexContainer>
    <RadioGroup onChange={handleChange} value={value}>
      <StyledRow gutter={[4, 4]}>
        {chartTypes.map((itemProps, i) => <ChartIconContainer key={itemProps.type}
                                                              $factor={getFactor(i)}>
          <ChartTypeItem {...{
            ...itemProps,
            checked: itemProps.type === value,
          }}/>
        </ChartIconContainer>)}
      </StyledRow>
    </RadioGroup>
  </FlexContainer>
}

export default ChartTypeSelector

const RadioGroup = styled(Radio.Group)`
  // Override ant-radio-group in order to properly align content
  &&& {
    display: flex;
    border: none;
  }

  &&& :focus-within {
    box-shadow: none !important;
  }
`

const FlexContainer = styled.div`
  display: flex;
`

const StyledRow = styled(Row)`
  gap: 4px;
`

const ChartIconContainer = styled.div<{ $factor: number }>`
    ${({$factor}) => `flex: calc((100% / ${$factor}) - 8px);`}
  max-height: 38px;
  `