/* eslint-disable max-lines */
import React, {forwardRef, useCallback, useMemo} from "react"
import {EChartOption} from "echarts"
import EChartContainer from "components/charts/Chart.Container"
import ChartBase, {BaseChartRef} from "components/charts/Chart.Base"
import {ChartSelection} from "classes/workflows/query-workflows/QueryWorkflow"
import {ELineChartProps, OriginalSerie} from "../line/LineChart.types";
import {ChartFormat, ColorName} from "@biron-data/react-bqconf";
import {useGetCircularOptions, useGetHorizontalOptions} from "./GaugeChart.utils";
import styled from "styled-components";
import {formatValue} from "../../../commons/format/formatter";

export const EGaugeChart = forwardRef<BaseChartRef, ELineChartProps>(function EGaugeChart(props, ref) {
  const {extraConf} = props.rawChartData.meta

  const min = useMemo(() => extraConf.boundaries?.min ?? 0, [extraConf.boundaries])
  const getMax = useCallback((serie: OriginalSerie) => extraConf.boundaries?.max && extraConf.boundaries.max > serie.values[0] ? extraConf.boundaries.max : serie.values[0], [extraConf.boundaries])
  const isResumed = props.dimensions.height < 190
  const metricDataTree = useMemo(() => props.rawChartData.parsedData[0], [props.rawChartData.parsedData])
  const color = useMemo(() => metricDataTree.metric.extraConf?.color ?? ColorName["blue-border"], [metricDataTree])

  const series = useMemo(() => metricDataTree.getSeries(), [metricDataTree])

  const chartDimension = useMemo(() => ({
    width: props.dimensions.width < 200 ? props.dimensions.width : 200,
    height: props.dimensions.height < 200 ? props.dimensions.height : 200,
  }), [props.dimensions.height, props.dimensions.width])

  const circularOptions = useGetCircularOptions(
    series,
    props.dimensions,
    extraConf.marks ?? [],
    min,
    isResumed,
    getMax,
    color,
    chartDimension.height
  )

  const horizontalOptions = useGetHorizontalOptions(
    series,
    props.dimensions,
    metricDataTree.format,
    extraConf.marks ?? [],
    min,
    metricDataTree.metric.metricAlias,
    getMax,
    color
  )

  const options: EChartOption = useMemo(() => {
    return props.selection.format === ChartFormat.GAUGE_FLAT ? horizontalOptions : circularOptions
    // we force dimensions as dep so echart does redraw
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selection.format, circularOptions, horizontalOptions, props.dimensions])

  return <ChartBase ref={ref}
                    sidePart={props.selection.format === ChartFormat.GAUGE_CIRCLE && <SidePart>
                      <MetricAlias $chartHeight={chartDimension.height}>{metricDataTree.metric.metricAlias}</MetricAlias>
                      <MetricValue $chartHeight={chartDimension.height}>{formatValue(series[0].values[0], props.rawChartData.parsedData[0].format)}</MetricValue>
                    </SidePart>}
                    option={options}
                    dimensions={chartDimension}
  />
})

const SidePart = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 100%;
    gap: 15%;
`


const MetricAlias = styled.div<{ $chartHeight: number }>`
    display: flex;
    width: 100%;
    justify-content: end;
    text-align: end;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 14px;
    color: var(--medium-grey);
    ${({$chartHeight}) => `padding-right: ${($chartHeight * 0.25) / 2}px;`}
`


const MetricValue = styled(MetricAlias)`
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    overflow-wrap: break-word;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    color: var(--dark-text);    
`

export interface GenericEChartProps {
  chartData: any
  dimensions: {
    height: number,
    width: number,
  }
  selection: ChartSelection
  withSummary: boolean
}

export default forwardRef<any, GenericEChartProps>(function GaugeChart(props, ref) {
  return <EChartContainer ref={ref}
                          chart={EGaugeChart}
                          rawChartData={props.chartData}
                          dimensions={props.dimensions}
                          selection={props.selection}
                          withSummary={props.withSummary}/>
})
