import React, {useCallback, useMemo, useRef, useState} from 'react'
import FormModal from "components/forms/Form.Modal"
import FormComponentGeneric from "components/forms/chart/FormComponentGeneric"
import {
  useRequirements,
  MetaModel,
  converterFilterToConfModel,
  extractSlicerDate,
  useIsAxisOptionAllowed,
  getMetricDef,
  DataSelection,
  GenericChartTypes,
  MetricDef,
  LoadDictionaryEntriesWithoutEnvironment,
  convertMetricHavingToConfFilters,
  useMetricFilterConverterToConfModel,
} from "@biron-data/react-bqconf"
import {
  formatFormDataToChartDto,
  uniqueViewOptions,
  useDimensionFilterConverterToConfModel,
  useMetricAliasWithoutViewFormatter,
} from "components/forms/chart/utils"
import {GenericExtendedConfModel, SimplifiedChartGenericFormProps} from "components/forms/chart/types"
import {ChartGenericDtoDetail} from "types/charts"
import {uniq} from "lodash"
import {Granularity} from "@biron-data/period-resolver"
import Language from "language"
import {useAdditionalDetails, useHandlePartialFormDataChange} from "components/forms/chart/hooks"
import {useLanguageResolver} from "@biron-data/react-contexts"
import {useGetPopupContainer} from '@biron-data/react-hooks'
import {ChartGenericWithoutLayout} from "components/widgetContainer/WidgetContainer"

interface Props {
  data: ChartGenericWithoutLayout
  environmentId: number
  metaModel: MetaModel
  dashboardSelection: DataSelection
  loadDictionaryEntries: LoadDictionaryEntriesWithoutEnvironment,
  onConfirm: (data: Omit<ChartGenericDtoDetail, "h" | "w" | "x" | "y">, requiredViewsCode: string[]) => void
  onCancel?: () => void
  dashboardId: number,
}

const initDateSlicer = (data: ChartGenericWithoutLayout) => extractSlicerDate(data.slicers)?.granularity ?? null

const FormChartGenericCmp: (props: Props) => JSX.Element | null = ({
                                                                     data: originalData,
                                                                     environmentId,
                                                                     dashboardId,
                                                                     metaModel,
                                                                     dashboardSelection,
                                                                     loadDictionaryEntries,
                                                                     onConfirm,
                                                                     onCancel,
                                                                   }) => {
  const lr = useLanguageResolver()
  const title = Language.get(`new-chart-title`)
  const popupContainerRef = useRef(null)
  const initialData = useMemo<ChartGenericWithoutLayout & { dateSlicerGranularity: Granularity | null, uniqueView: string | null }>(
    () => {
      const usedViewsCode = uniq(originalData.metrics.map(({viewCode}) => viewCode))
      return ({
        ...originalData,
        dateSlicerGranularity: initDateSlicer(originalData),
        uniqueView: (usedViewsCode.length === 1 && metaModel.getView(usedViewsCode[0])?.code) || null,
      })
    },
    [originalData, metaModel],
  )

  const [formData, setData] = useState<GenericExtendedConfModel>({
    ...initialData,
    ...{
      ...initialData.extraConf,
      format: initialData.extraConf.format ?? null,
      slicers: initialData.slicers.map(slicer => ({
        ...slicer,
        isDefault: false,
      })),
      metrics: originalData.metrics.map(metric => ({
        ...metric,
        metricDef: getMetricDef(metaModel, metric) as MetricDef,
        additionalFilters: converterFilterToConfModel(metric.additionalFilters),
      })),
      filters: converterFilterToConfModel(initialData.filters),
      metricFilters: convertMetricHavingToConfFilters(initialData.metrics),
      orderBys: initialData.orderBys?.map(orderBy => ({
        ...orderBy,
        isDefault: false,
      })) ?? [],
      limits: initialData.extraConf?.limits?.map(limit => ({
        ...limit,
        isDefault: false,
      })),
    },
  })

  const {
    availableViews: viewsWithMetrics,
    unavailableViews,
    requiredViewsCode,
    availableDimensions,
    unavailableDimensions,
  } = useRequirements(metaModel,
    formData.uniqueView,
    formData.slicers,
    formData.metrics)

  const dimensionFilterConverter = useDimensionFilterConverterToConfModel(formData.filters, availableDimensions)
  const metricAliasFormatter = useMetricAliasWithoutViewFormatter(formData.metrics, viewsWithMetrics)
  const metricFilterConverter = useMetricFilterConverterToConfModel(metaModel, formData.metricFilters, !formData.uniqueView, viewsWithMetrics)

  const consolidatedFormData: GenericExtendedConfModel = useMemo(() => {
    const formattedMetrics = metricAliasFormatter()
    return {
      ...formData,
      filters: dimensionFilterConverter(),
      metrics: formattedMetrics.map(metric => ({
        ...metric,
        additionalFilters: converterFilterToConfModel(metric.additionalFilters, availableDimensions),
      })),
      metricFilters: metricFilterConverter(formattedMetrics),
    }
  }, [availableDimensions, dimensionFilterConverter, formData, metricAliasFormatter, metricFilterConverter])

  const getPopupContainer = useGetPopupContainer(popupContainerRef.current)

  const additionalDetails = useAdditionalDetails(formData.type, formData.displayType)

  const formProps = useMemo<SimplifiedChartGenericFormProps>(() => ({
      uniqueViewOptions: uniqueViewOptions(metaModel),
      metricInvertible: formData.displayType === GenericChartTypes.BOXES || formData.displayType === GenericChartTypes.TABLES,
      loadDictionaryEntries,
      metaModel,
      environmentId,
      dashboardId,
      viewsWithMetrics,
      unavailableViews,
      requiredViewsCode,
      additionalDetails,
      availableDimensions,
      unavailableDimensions,
      getPopupContainer,
      dashboardSelection,
    }),
    [metaModel, formData.displayType, loadDictionaryEntries, environmentId, dashboardId, viewsWithMetrics, unavailableViews, requiredViewsCode, additionalDetails, availableDimensions, unavailableDimensions, getPopupContainer, dashboardSelection],
  )

  const isAxisOptionAllowed = useIsAxisOptionAllowed(formData.displayType, formData.format)

  const handleConfirm = useCallback(
    (newData: GenericExtendedConfModel) => {
      onConfirm(formatFormDataToChartDto({
        ...formData,
        ...newData,
      }, isAxisOptionAllowed, viewsWithMetrics, !formData.uniqueView, metaModel, availableDimensions, lr, title), requiredViewsCode)
    }, [availableDimensions, formData, isAxisOptionAllowed, lr, metaModel, onConfirm, requiredViewsCode, title, viewsWithMetrics],
  )

  const handlePartialFormDataChange = useHandlePartialFormDataChange(setData)

  return <FormModal<GenericExtendedConfModel, SimplifiedChartGenericFormProps> {...{
    ref: popupContainerRef,
    defaultTitle: title,
    isTitleEditable: true,
    width: 1500,
    data: consolidatedFormData,
    setData: handlePartialFormDataChange,
    renderFormComponent: (props) => <FormComponentGeneric {...props}/>,
    onConfirm: handleConfirm,
    onCancel,
    formProps,
  }} />
}

export default FormChartGenericCmp
