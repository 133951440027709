import {useCallback, useMemo} from "react";
import {OriginalSerie} from "../line/LineChart.types";
import {formatValue} from "../../../commons/format/formatter";
import {Mark} from "../../forms/selector/mark/MarkSelector";
import {ColorName, Format, strToColor} from "@biron-data/react-bqconf";
import {EChartOption} from "echarts";

const findClosestMark = (angle: number) => {
  angle = ((angle % 360) + 360) % 360

  const lowerMark = Math.floor(angle / 10) * 10

  const upperMark = (lowerMark + 10) % 360

  const lowerDiff = angle - lowerMark
  const upperDiff = upperMark - angle

  return lowerDiff <= upperDiff ? lowerMark : upperMark
}

const getDistance = (anglePosition: number) => {
  let consolidatedAngle = Math.floor(anglePosition)
  if (consolidatedAngle > 90 && consolidatedAngle < 180) {
    consolidatedAngle -= 90
  } else if (consolidatedAngle >= 180 && consolidatedAngle < 270) {
    consolidatedAngle -= 180
  } else if (consolidatedAngle >= 270 && consolidatedAngle <= 360) {
    consolidatedAngle -= 270
  }

  if (anglePosition <= 290 && anglePosition >= 250) {
    return -10
  }
  return 48 + (consolidatedAngle / 1.5)
}

const useGetCircularMarks = (
  marks: Mark[],
  min: number,
  isResumed: boolean,
  markLength: number,
  labelSize: number,
  getMax: (serie: OriginalSerie) => number,
  height: number
) => {
  return useCallback((serie: OriginalSerie,) => marks.map((mark: Mark) => {
    const label = mark.name ? `${mark.name}: ${mark.value}` : mark.value
    const max = getMax(serie)
    const anglePosition = (mark.value / (max - min)) * 360

    const closestMark = findClosestMark(anglePosition)

    const distance = getDistance(anglePosition)

    return getMax(serie) < mark.value ? undefined : ({
      type: "gauge",
      center: [height / 2, "50%"],
      z: 3,
      startAngle: 89,
      endAngle: 90,
      min,
      max,
      splitNumber: 36,
      progress: {
        show: false,
      },
      pointer: {
        showAbove: false,
        icon: 'rect',
        length: markLength,
        width: 2,
        offsetCenter: [0, '-70%'],
        itemStyle: {
          color: strToColor.get(mark.color),
        }
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        show: !isResumed,
        backgroundColor: 'white',
        color: '#464646',
        fontHeight: 10,
        fontSize: labelSize,
        fontFamily: 'Poppins',
        distance: distance * -0.9,
        rotate: 0,
        width: 70,
        overflow: 'truncate',
        formatter: (value: any) => {
          const angle = Math.floor((value / (max - min)) * 360)

          if (angle === closestMark) {
            return label;
          }
          return '';
        },
        itemStyle: {
          align: 'middle',
          verticalAlign: 'middle',
        }
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      anchor: {
        show: false
      },
      title: {
        show: false,
      },
      detail: {
        show: false,
      },
      data: [{value: mark.value}],
    })
  }), [getMax, height, isResumed, labelSize, markLength, marks, min])
}

export const useGetCircularOptions = (
  series: OriginalSerie[],
  dimensions: { height: number, width: number },
  marks: Mark[],
  min: number,
  isResumed: boolean,
  getMax: (serie: OriginalSerie) => number,
  color: ColorName,
  height: number
): EChartOption => {
  const sizeReference = useMemo(() => dimensions.height < 200 ? dimensions.height / 100 : 2, [dimensions])
  const markLength = sizeReference * 16
  const markDistance = markLength / 6
  const labelSize = 10

  const getCircularMarks = useGetCircularMarks(marks, min, isResumed, markLength, labelSize, getMax, height)

  const barWidth = sizeReference * 10

  return useMemo(() => {
    return {
      tooltip: {
        show: true,
      },
      series: series.flatMap((serie: OriginalSerie) => {
        const max = getMax(serie)
        return [
          {
            type: "gauge",
            center: [height / 2, "50%"],
            radius: '75%',
            startAngle: 89,
            endAngle: 90,
            min,
            max: getMax(serie),
            progress: {
              show: true,
              width: barWidth,
              itemStyle: {
                color: strToColor.get(color),
              }
            },
            pointer: {
              show: false
            },
            axisLine: {
              lineStyle: {
                width: barWidth,
                opacity: 0.1,
                color: [[1, strToColor.get(color)]], // Specify color stops
              }
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            anchor: {
              show: false
            },
            title: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [{value: serie.values[0]}],
            animationEasingUpdate: "cubicIn",
          },
          {
            type: "gauge",
            center: [height / 2, "50%"],
            radius: '75%',
            z: 3,
            startAngle: 90 - ((360 / max) * serie.values[0]),
            endAngle: 90 - ((360 / max) * serie.values[0]),
            min,
            max,
            splitNumber: 1,
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                width: 0,
              }
            },
            axisLabel: {
              show: false,
            },
            splitLine: {
              show: true,
              distance: -1 * markDistance,
              length: markLength,
              lineStyle: {
                width: 2,
                color: 'black',
              }
            },
          },
          ...getCircularMarks(serie)
        ]
      })
    } as EChartOption
  }, [barWidth, color, getCircularMarks, getMax, height, markDistance, markLength, min, series])
}


export const useGetHorizontalMarks = (
  marks: Mark[],
  startXPosition: number,
  barWidth: number,
  labelSize: number,
  markPosition: number,
  markHeight: number,
  format: Format,
  getMax: (serie: OriginalSerie) => number
) => useCallback((serie: OriginalSerie) => marks.flatMap((mark: Mark) => getMax(serie) < mark.value ? undefined : [{
  type: 'rect',
  shape: {
    x: startXPosition + ((barWidth / getMax(serie)) * mark.value),
    y: -1 * (markPosition),
    width: 2,
    height: markHeight,
  },
  style: {
    fill: strToColor.get(mark.color),
    borderColor: strToColor.get(mark.color),
    borderWidth: 30,
  }
},
  {
    type: 'text',
    style: {
      text: formatValue(mark.name ? `${mark.name}: ${mark.value}` : mark.value, format),
      fontSize: labelSize,
      fontFamily: 'Poppins',
      x: startXPosition + ((barWidth / getMax(serie)) * mark.value),
      y: -1 * (markPosition + (markHeight / 3)),
      align: 'middle',
      verticalAlign: 'middle',
      backgroundColor: 'white',
      fill: "#94949E",
    },
  }]), [barWidth, labelSize, format, getMax, markHeight, markPosition, marks, startXPosition])


export const useGetHorizontalOptions = (
  series: OriginalSerie[],
  dimensions: { height: number, width: number },
  format: Format,
  marks: Mark[],
  min: number,
  metricAlias: string,
  getMax: (serie: OriginalSerie) => number,
  color: ColorName
) => {
  const sizeReference = useMemo(() => {
    if (dimensions.height < 150) {
      return 4.2
    } else if (dimensions.height < 200) {
      return (dimensions.height / 100) + 4
    }
    return 6
  }, [dimensions])
  const barWidth = useMemo(() => dimensions.width, [dimensions.width])
  const getValueBarWidth = useCallback((serie: OriginalSerie) => (barWidth / getMax(serie)) * serie.values[0], [barWidth, getMax])
  const startXPosition = useMemo(() => (dimensions.width / 2) - (barWidth / 2), [barWidth, dimensions.width])
  const endXPosition = useMemo(() => (dimensions.width / 2) + (barWidth / 2), [barWidth, dimensions.width])

  const fontSize = 14
  const labelSize = 10
  const headerFontSize = 24

  const textPosition = sizeReference * 4
  const titlePosition = 80
  const subTitlePosition = 50

  const progressBarHeight = sizeReference * 3

  const markHeight = sizeReference * 6
  const markPosition = sizeReference * 2

  const getHorizontalMarks = useGetHorizontalMarks(marks, startXPosition, barWidth, labelSize, markPosition, markHeight, format, getMax)
  return useMemo(() => {
    return {
      graphic: {
        elements: series.flatMap((serie: OriginalSerie) => [
          {
            type: 'group',
            top: 'center',
            children: [
              {
                type: 'text',
                style: {
                  text: metricAlias,
                  fontSize,
                  fontFamily: 'Poppins',
                  x: startXPosition,
                  y: -1 * (titlePosition),
                  align: 'start',
                  verticalAlign: 'start',
                  fill: "#94949E",
                },
              },
              {
                type: 'text',
                style: {
                  text: formatValue(serie.values[0], format),
                  fontSize: headerFontSize,
                  fontFamily: 'Poppins',
                  x: startXPosition,
                  y: -1 * (subTitlePosition),
                  align: 'start',
                  verticalAlign: 'start',
                },
              },
              {
                type: 'rect',
                shape: {
                  x: startXPosition,
                  y: 0,
                  width: barWidth,
                  height: progressBarHeight,
                },
                style: {
                  fill: strToColor.get(color),
                  opacity: 0.1
                },
              },
              {
                type: 'rect',
                shape: {
                  x: startXPosition,
                  y: 0,
                  width: getValueBarWidth(serie),
                  height: progressBarHeight,
                },
                style: {
                  fill: strToColor.get(color),
                }
              },
              {
                type: 'text',
                style: {
                  text: formatValue(min, format),
                  fontSize: labelSize,
                  fontFamily: 'Poppins',
                  x: startXPosition,
                  y: textPosition,
                  align: 'start',
                  verticalAlign: 'start',
                  fill: "#94949E",
                },
              },
              {
                type: 'text',
                style: {
                  text: formatValue(getMax(serie), format),
                  fontSize: labelSize,
                  fontFamily: 'Poppins',
                  x: endXPosition - (formatValue(getMax(serie), format).length * (labelSize / 1.8)),
                  y: textPosition,
                  align: 'end',
                  verticalAlign: 'end',
                  fill: "#94949E",
                },
              },
              {
                type: 'rect',
                shape: {
                  x: startXPosition + getValueBarWidth(serie),
                  y: -1 * (markPosition),
                  width: 2,
                  height: markHeight,
                },
                style: {
                  fill: '#000',
                  borderColor: '#000',
                  borderWidth: 30,
                }
              },
              ...getHorizontalMarks(serie)
            ]
          }
        ])
      }
    }
  }, [series, metricAlias, startXPosition, format, barWidth, progressBarHeight, color, getValueBarWidth, min, textPosition, getMax, endXPosition, markPosition, markHeight, getHorizontalMarks])
}